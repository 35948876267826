import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";

import PageBlock from "../../components/Layout/PageBlock";
import PageLayout from "../../components/Layout/PageLayout";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

import { ArrowRight, ExternalLink } from "react-feather";
import { PageBlockTitle, CTALink, staticImgContainer, InlineExternalLink, InlineEmailLink } from "../../styles/component-stylings";
import { APPLY_LINK, DSC_EMAIL } from "../../helpers/links";

const PAGE_TITLE = "Opportunities";

const GraduatesPage = ({ pageContext }) => {
    return (
        <PageLayout pageTitle={PAGE_TITLE} pageContext={pageContext}>
            <Helmet>
                <meta name='description' content='Career opportunities for Digital Surveillance Collection from the Australian Federal Police' />
            </Helmet>
            <PageBlock pageContext={pageContext} backgroundColour='white' isFirstBlock>
                <Breadcrumb pageContext={pageContext} />
                <h1>{PAGE_TITLE}</h1>
                <p>
                    We are passionate about fostering upcoming talent to join us in working on some interesting and meaningful work, no matter where you are in
                    your career.
                </p>
                <p>There are many pathways to DSC:</p>
                <PageBlockTitle>Graduate program</PageBlockTitle>
                <StaticImage
                    style={staticImgContainer}
                    imgStyle={{ objectPosition: "center 35%" }}
                    src='../../images/graduate.jpg'
                    alt='Graduate'
                    placeholder='blurred'
                    loading='lazy'
                />
                <p>
                    The AFP Graduate Program gives university graduates who are passionate about the work of the Australian Federal Police a unique path to a
                    range of challenging and rewarding roles.
                </p>
                <p>
                    The 12-month program provides permanent, full-time employment in specialist or support roles, working in a variety of areas and playing a
                    key role in helping the AFP stay a step ahead of crime. At the end of the program, you will continue to work in the area that recruited you.
                </p>
                <CTALink to='https://jobs.afp.gov.au/job-opportunities/graduate-program' target='_blank' rel='external'>
                    <span>Learn more</span>
                    <ExternalLink />
                </CTALink>

                <PageBlockTitle>Apply directly</PageBlockTitle>
                <StaticImage
                    style={staticImgContainer}
                    imgStyle={{ objectPosition: "center 75%" }}
                    src='../../images/job-apply.jpg'
                    alt='Job Interview'
                    placeholder='blurred'
                    loading='lazy'
                />

                <p>
                    We often post open vacancies for positions to join DSC on the{" "}
                    <InlineExternalLink href={APPLY_LINK} target='_blank' rel='external'>
                        AFP Jobs page
                    </InlineExternalLink>
                    . If you feel you meet the skills and requirements of these positions, consider applying for the position directly.
                </p>
                <p>
                    There will be times were there are no posted vacancies for DSC, but don't let this deter you. Consider{" "}
                    <InlineEmailLink href={`mailto:${DSC_EMAIL}?subject=[DSC Recruitment]`}>sending us your CV</InlineEmailLink> directly. Please reach out to
                    our team for a confidential discussion - we are <strong>always</strong> looking for outstanding candidates.
                </p>
                <p>
                    <strong>Candidates must be Australian citizens and able to obtain an Australian Government security clearance.</strong>
                </p>

                <CTALink to={APPLY_LINK} target='_blank' rel='external'>
                    <span>Apply now</span>
                    <ExternalLink />
                </CTALink>

                <PageBlockTitle>Dandelion Program</PageBlockTitle>
                <StaticImage
                    style={staticImgContainer}
                    imgStyle={{ objectPosition: "center 10%" }}
                    src='../../images/diversity-inclusion-banner.png'
                    alt='dandelion'
                    placeholder='blurred'
                    loading='lazy'
                />
                <p>
                    The Dandelion Program provides specialised recruitment and support services for members of the <strong> neurodivergent community </strong>
                    to join a 2-year traineeship with DSC.
                </p>
                <p>
                    Gain a role tailored for specific aptitudes and workplace motivations, with
                    <strong> ongoing ASC (Autism Support Consultant) support </strong> for the trainee, managers and co-workers involved.
                </p>
                <CTALink to='/careers/dandelion-program' rel='next'>
                    <span>Explore the Dandelion Program</span>
                    <ArrowRight />
                </CTALink>

                <PageBlockTitle>Technical specialist?</PageBlockTitle>
                <StaticImage
                    style={staticImgContainer}
                    imgStyle={{ objectPosition: "center 40%" }}
                    src='../../images/expert-2.jpg'
                    alt='Specialist'
                    placeholder='blurred'
                    loading='lazy'
                />
                <p>Are you a technical specialist looking for a change?</p>
                <p>Do you want to work on innovative and ground-breaking technologies, with the added benefit of keeping Australia safe?</p>
                <CTALink to='/careers/technical-specialist-framework' rel='next'>
                    <span>Explore TSF</span>
                    <ArrowRight />
                </CTALink>
            </PageBlock>
        </PageLayout>
    );
};

export default GraduatesPage;
